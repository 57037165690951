/*
 * HTML5 Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

*{
  box-sizing: border-box;
}

html,
button,
input,
select,
textarea {
  color: var(--main-color);
}

body {
  font-size: 1em;
  line-height: 1.4;

}

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: var(--selection-color);
  text-shadow: none;
}

::selection {
  background: var(--selection-color);
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--hr-color);
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between images and the bottom of their containers: h5bp.com/i/440
 */

img {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

/* ==========================================================================
   Browse Happy prompt
   ========================================================================== */

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}



a {
  text-decoration: none;
  color: inherit;
}


.wrapper{
  width: 80%;
  max-width: 1200px;
  margin: auto;
}



.text-center {
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.margin-top{
  margin-top: 240px;
}

.columns-third {
  display: inline-block;
    width: 33%;
    vertical-align: top;
    float: left;
}

.columns-half {
  width: 50%;
  display: inline-block;
  float: left;
}

.columns-half img{
  width: 100%;
}

body {
  width: 100%;
  margin:0 auto;
  font-weight: 300;
  letter-spacing: 1px;
}

input,
textarea{
  display: block;
  margin-bottom: 10px;
  background: #f9f9f9;
  border: 1px solid #eeeeee;
  width: 100%;
  padding: 10px;
}

textarea{
    resize: none;
}

input[type=submit] {
  background: #eeeeee;
}

input[type=submit]:hover{
  background: #DDDDDD;
} 

@media screen and (max-width: 900px) { 
  .wrapper{
    width: 95%;
  }
}

@media screen and (max-width: 665px) { 
  .wrapper{
    
  }
  .columns-third { 
    width: 90%;
    margin: auto;
    display: block;
    float: none;
  }
  .detail-container{
        width: 80%;
    margin: 0 auto 40px;
  }

  .margin-top{
    margin-top: 120px;
  }

  .columns-half {
    width: 100%;
    float: none;
    display: block;
    margin:auto;
  }


}