/**
 * All styles related to markup inside the footer
 */

footer {
    background: var(--dark-color);
    height: 63px;
}
footer .footer-left-side {
    display: table;
    width: 50%;
    padding: 0 0 0 40px;
    font-size: 12px;
    color: #CCC;
    height: 100%;
    float: left;
}
footer .footer-right-side {
    display: table;
    width: 50%;
    padding: 0 0 0 40px;
    font-size: 12px;
    color: #CCC;
    height: 100%;
    float: right;
}
footer p {
    margin: 0;
    vertical-align: middle;
    display: table-cell;
}
@media screen and (max-width: 767px) {
	footer {
		height: auto;
	}
    footer .footer-left-side {
        width: 100%;
        text-align: center;
        padding: 0;
        float: none;
        height: 50px;
    }
    footer .footer-right-side {
        width: 100%;
        text-align: center;
        padding: 0;
        float: none;
        height: 50px;
    }

    footer .socials {
    	margin: auto;
    	width: 80px;
    	display: block;
    }
}
