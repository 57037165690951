/**
 * Typography is specified in here.
 */

body {
  font-family: var(--font-main);
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-key);
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
}


h2,h3{
  font-size: 40px;
  line-height: 50px;
}

h5{
    font-size: 1em;
	font-weight: 400;
	text-transform: uppercase;
	margin: 0;
	color: #fefefe;
}

@media screen and (max-width: 440px) { 
  h2,h3{ 
    font-size: 30px;
    line-height: 40px;
  }
}