/**
 * All styles related to markup inside the header
 */

header {
    height: 83px;
    padding: 10px 0;
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
}
header h1 {
    float: left;
    text-indent: -10000px;
    width: 90px;
    margin: 17px 0 0 40px;
    background-size: contain;
}
nav {
    float: right;
    margin: 17px 40px 0 0;
}
nav ul {
    margin: 10px 0;
}
nav ul, nav ul li {
    list-style: none;
    margin: 0;
    padding: 0;
}
nav ul li {
    display: inline-block;
    margin-left: 20px;
}
nav ul li a {
    text-transform: uppercase;
    font-size: 22px;
}
nav ul li a:hover, nav ul li a.active {
    color: #000;
}
@media screen and (max-width: 767px) {} @media screen and (max-width: 480px) {
    header {
        height: 43px;
    }
    header h1 {
        width: 55px;
        margin: 2px 0 0 20px;
        height: 20px;
    }
    nav {
        margin: 0px 20px 0 0;
    }
    nav ul li {
        margin-left: 10px;
    }
    nav ul li a {
        font-size: 0.9em;
    }
}
.socials {
    vertical-align: middle;
    display: table-cell;
}
.socials a {
    width: 30px;
    float: right;
    margin: 0 10px 0 0;
}
.socials a img {
    width: 100%;
}

.socials a img:hover {
	opacity: 0.8;
}
