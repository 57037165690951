/**
 * All styles related to markup inside the main element
 */

main {
  margin-top: 83px;
}

section {
  margin-bottom: 240px;
}

section p{
  width: 70%;
  margin: 20px auto;
}

.oculus{
    margin: 40px auto;
    max-width: 300px;
    width: 90%;
}

.oculus img{
  width: 100%;
}

section .socials {
      display: block;
    margin: 20px auto;
    width: 90px;
}

.details-image{
  margin: 60px 0;
  text-align: center;
}

.details-image img {
  width: 100%;
  height: auto;
  max-width: 600px;
}

section#discover {
  padding-top: 80px;
  margin-bottom: 80px;
}

section#discover .details-image{
  margin-bottom: 0;
}

section#details {
  background: var(--dark-color);
  color: var(--clear-color);
  font-size: 0.8em;
  padding: 80px 0;
}

section#details h2{
  margin-bottom: 80px;
}

.detail-container {
  margin-bottom: 40px;
}

.detail-icon{
  display: inline-block;
    width: 20%;
    float: left;
    text-align: center;
}

.detail-content{
  display: inline-block;
    width: 80%;
    float: right;
}

.detail-content p {
  margin: 0;
    width: 100%;
}

.video-thumbnail{
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.video-thumbnail iframe{
    width: 100%;
    height: 100%;
    position: absolute;
    border: 0;
    z-index: 100;
    display: none;
}

.video-thumbnail img{
  height: auto;
  width: 100%;
  max-width: 848px;
}

.video-thumbnail .play-button{
  position: absolute;
  opacity: 0.8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
}

.video-thumbnail:hover .play-button{
  opacity: 1;
}

.columns-half h3 {
  margin-top: 8%;
}

.columns-half h3,
.columns-half p{
    margin-left: 80px;
}

#fix {
  padding-top: 240px;
  margin-bottom: 40px;
}

#fix .columns-half {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#fix p,
#fix h3{
  width: 70%;
  margin: auto;
}

#fix-details{
      height: 568px;
    width: auto;
    margin: auto;
    display: block;
}

#box-image{
  cursor: pointer;
}

#shop{
  padding: 80px 0 0;
  margin-bottom: 100px;
}

.shop-retailers {
  margin: 40px auto 60px;
  max-width: 600px;
}

.retailer {
  width: 30%;
  display: inline-block;
  margin: 0 3.5% 5%;
}

@media screen and (max-width: 440px) { 
  .retailer {
    width: 70%;
    display: block;
    margin: 10px auto;
    float: none;
  }
}

.retailer h5{
  color: #757575;
  margin:0 0 10px;
}

.retailer a {
  text-decoration: none;
  display: block;
}

.retailer a:hover {
  opacity: 0.8;
}

.retailer img {
  width: 100%;
  display: block;
}

@media screen and (max-width: 768px) { 
  #fix-details {
        height: auto;
    width: 100%;
  }
}

@media screen and (max-width: 665px) { 
  section {
    margin-bottom: 120px;
  }

  #fix .columns-half {
    position: relative;
    top: initial;
    transform: inherit;
  }

  .columns-half h3,
  .columns-half p{
    margin:0 auto;
    text-align: center;
  }

  #fix {
    padding:0;
  }

  #fix-details {
    width: 60%;
    height: auto;
    margin: 40px auto;
  }
}

@media screen and (max-width: 440px) { 
  main {
    margin-top: 43px;
  }
  section#discover {
    padding-top: 20px;
    margin-bottom: 0;
  }
}

.contact-form {
  width: 100%;
  margin: 20px auto;
  max-width: 600px;
  height: 500px;
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request: h5bp.com/r
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: #000 !important; /* Black prints faster: h5bp.com/s */
    box-shadow:none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; /* h5bp.com/t */
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
