/**
 * This project's description goes here
 *
 * @author  Some author's name
 * @version v0.0.0
 */

/* Helpers */
@import url('src/css/base/variables.css');
@import url('node_modules/normalize.css/normalize.css');
@import url('src/css/base/helpers.css');

/* Typography and Elements */
@import url('src/css/elements/typography.css');

/* Layout */
@import url('src/css/layout/base.css');
@import url('src/css/layout/header.css');
@import url('src/css/layout/main.css');
@import url('src/css/layout/footer.css');

/* Modules */
@import url('src/modules/module/module.css');

header h1 {
    background: url(../img/logo.png) no-repeat;
    background-size: contain;
}